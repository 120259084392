/* src/app/_shared/components/tables/mat-table-responsive/mat-table-responsive.component.scss */
:root {
  --icon-fill: #000;
  --icon-stroke: #000;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
:host {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  display: block;
}
:host:first-child {
  margin-top: 0;
}
:host:last-child {
  margin-bottom: 0;
}
@media (width <= 1199px) {
  :host {
    width: calc(100% + 80px);
    padding: 0 40px;
    margin-left: -40px;
    margin-right: -40px;
  }
}
@media (width <= 767px) {
  :host {
    width: calc(100% + 40px);
    padding: 0 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
/*# sourceMappingURL=mat-table-responsive.component.css.map */
